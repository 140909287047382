import { BreakpointsOptions } from '@mui/system/createTheme/createBreakpoints'
import { createTheme, Theme } from '@mui/material/styles'
import 'theme/fonts-gilroy.css'

import { typography } from './typography'

export const colors = {
	primary: '#E7354D',
	background: {
		default: '#FAFAFB',
		dark: '#1D1D1D',
		light: '#FFFFFF',
		paper: '#FFFFFF',
		review: '#E9E8E8',
	},
	videoBackground: '#DFE5EB',
	white: '#FFFFFF',
	black: '#28272C',
	red: '#E7354D',
	yellow: '#F7E769',
	blue1: '#17BEBB',
	green: '#3CB877',
	blue: '#85CFEF',
}

const breakpoints: BreakpointsOptions = {
	values: {
		xs: 0,
		sm: 600,
		md: 900,
		lg: 1200,
		xl: 1560,
	},
}

const defaultTheme = createTheme({ breakpoints })
const theme = createTheme({
	palette: {
		primary: {
			main: colors.primary,
			// for gradient
			dark: '#E22159',
			light: '#EC4A42',
		},
		secondary: {
			main: '#ffffff',
		},
		text: {
			primary: '#13192C',
			secondary: '#FFFFFF',
		},
		background: colors.background,
	},
	shape: { borderRadius: 8 },
	//prettier-ignore
	shadows: [
		'none', 'none', 'none', 'none', 'none',
		'none', 'none', 'none', 'none', 'none',
		'none', 'none', 'none', 'none', 'none',
		'none', 'none', 'none', 'none', 'none',
		'none', 'none', 'none', 'none', 'none',
	],
	breakpoints,
	components: {
		MuiCssBaseline: {
			styleOverrides: {
				':root': {
					scrollBehavior: 'auto',
				},
				'svg:not([data-keep-colors])': {
					path: {
						fill: 'currentColor',
					},
				},
				'section, header, footer': {
					paddingLeft: '1rem',
					paddingRight: '1rem',
					[defaultTheme.breakpoints.up('md')]: {
						paddingLeft: '3rem',
						paddingRight: '3rem',
					},
					[defaultTheme.breakpoints.up('lg')]: {
						paddingLeft: '5rem',
						paddingRight: '5rem',
					},
				},
				article: {
					maxWidth: '1560px',
					marginLeft: 'auto',
					marginRight: 'auto',
					position: 'relative',
					overflow: 'visible',
				},
				hr: {
					margin: 0,
					borderLeft: 'none',
					borderRight: 'none',
				},
				a: {
					textDecoration: 'none',
					color: 'inherit',
				},
			},
		},
		MuiTypography: {
			defaultProps: { color: 'textPrimary' },
			styleOverrides: { root: { whiteSpace: 'pre-line' } },
		},
		MuiButton: {
			defaultProps: { variant: 'contained' },
			styleOverrides: {
				root: {
					textTransform: 'none',
					padding: '1rem 3rem',
					borderRadius: '3px',
				},
				endIcon: {
					marginLeft: '1.5rem',
				},
			},
			variants: [
				{
					props: { variant: 'contained', color: 'primary', disabled: false },
					style: { background: 'linear-gradient(98.7deg, #E22159 -2.37%, #EC4A42 102.94%)' },
				},
			],
		},
		MuiAppBar: {
			defaultProps: { position: 'fixed' },
			styleOverrides: {
				root: {
					flexDirection: 'row',
					justifyContent: 'center',
					transition: 'background 300ms ease',
				},
			},
		},
		MuiGrid: {
			defaultProps: {
				spacing: 4,
			},
		},
		MuiCheckbox: {
			styleOverrides: {
				root: {
					color: colors.primary,
					'&.Mui-checked': { color: colors.primary },
				},
			},
		},
		MuiTextField: {
			defaultProps: {
				fullWidth: true,
				variant: 'filled',
				color: 'primary',
			},
		},
		MuiFilledInput: {
			styleOverrides: {
				root: {
					color: 'inherit',
					borderBottomLeftRadius: '8px',
					borderBottomRightRadius: '8px',
					border: '1px solid #3C3C3C',

					// underline
					':before': { borderBottomColor: 'transparent !important' },
					':after': { borderBottomColor: 'transparent !important' },
				},
				input: { color: 'inherit' },
			},
			variants: [
				{
					props: { color: 'primary' },
					style: { background: '#FAFAFB !important' },
				},
				{
					props: { color: 'secondary' },
					style: { background: '#3C3C3C !important' },
				},
			],
		},
		MuiInputLabel: {
			styleOverrides: { root: { color: 'inherit !important', opacity: 0.92 } },
		},
	},
	typography,
	...{ colors },
})

export interface CustomTheme extends Theme {
	palette: Theme['palette'] & {
		background: typeof colors.background
	}
	colors: typeof colors
}

export default theme
