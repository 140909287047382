import ReactPixel from 'react-facebook-pixel';

export const initFacebookPixel = (): void => {
	const options = {
		autoConfig: true, // Enable auto configuration
		debug: false,     // Disable debug logs
	};

	ReactPixel.init('1489702101739941', {}, options);
	ReactPixel.pageView(); // Track initial page view
};
