import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'

import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'

import theme from '../../src/theme'

const queryClient = new QueryClient()

const gtmId = 'GTM-N3QGTNT';

export default function TopLayout(props) {
	return (
		<ThemeProvider theme={theme}>
			<StyledThemeProvider theme={theme}>
				<StyledEngineProvider injectFirst>
					<CssBaseline />
					<QueryClientProvider client={queryClient}>
						<noscript id={'gtag-noscript'}>
							<iframe
								title={'gtag-noscript'}
								src={`https://www.googletagmanager.com/ns.html?id=${gtmId}`}
								height="0"
								width="0"
								style={{ display: 'none', visibility: 'hidden' }}
							/>
						</noscript>
						{props.children}
					</QueryClientProvider>
				</StyledEngineProvider>
			</StyledThemeProvider>
		</ThemeProvider>
	)
}
